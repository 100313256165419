import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import BookInfo from "../components/BookInfo"
import Image from "gatsby-image"
import { useSpring, animated } from "react-spring"
import { FiShoppingCart } from "react-icons/fi"
import { useStore } from "../store"
import slugify from "slugify"
import SEO from "../components/seo"

export const noticiaQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      rawMarkdownBody
      timeToRead
      excerpt
      frontmatter {
        title
        author
        date(locale: "ca", formatString: "LL")
        images {
          sharp: childImageSharp {
            fluid(maxWidth: 1024, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
      }
    }
  }
`

const NoticiaTemplate = ({ data: { markdownRemark: noticia } }) => {
  if(!noticia) {
    return <p>...</p>
  }
  
  return (
    <Layout>
      <SEO
        title={`${noticia.frontmatter.title} | Noticies`}
        description={noticia.frontmatter.excerpt}
        lang="ca"
        keywords={[
          "noticies",
          "blog",
          "blog de les males herbes",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="flex flex-wrap">
        <div className="w-full">
          <p className="font-bold uppercase text-xl border-b border-black  py-2">
            Notícies
          </p>
        </div>
      </div>
      <div className="w-full mb-8">
        <p className="text-3xl mt-8">{noticia.frontmatter.title}</p>
      </div>
      <section class="flex flex-wrap mb-4">
        <div class="w-full md:w-1/2 ">
          <p className=" font-semibold">{noticia.frontmatter.date}</p>
          <p className="mt-1 font-medium">Per {noticia.frontmatter.author}</p>
          <p className="mt-1">
            Temps de lectura, {noticia.timeToRead} minut
            {noticia.timeToRead > 1 && `s`}
          </p>
        </div>

        <div class="w-full md:w-1/2 mt-8 md:mt-0">
          <Image
            fluid={noticia.frontmatter.images.sharp.fluid}
            className={"h-auto"}
          />
          <br />
          <div
            className="mt-2 text-lg"
            dangerouslySetInnerHTML={{
              __html: noticia.html,
            }}
          />
        </div>
      </section>
    </Layout>
  )
}

export default NoticiaTemplate
